'use client';

import Footer from '@/src/components/Footer/Footer';
import NavBar from '@/src/modules/LandingPage/components/NavBar';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import Providers from './providers';

export default function Layout({ children }: { children: React.ReactNode }) {
  const params = usePathname();

  return (
    <Providers>
      <div className="overflow-y-auto overflow-x-hidden w-full flex flex-col justify-start items-center max-h-full ic-disable-resp-font relative">
        <NavBar disableAnimation={true} />
        <main className="w-full flex flex-col items-center justify-start bg-[#f4f4f4] ic-disable-resp-font">
          {children}
        </main>
        {params !== '/contact-us' && (
          <div className="fixed xs:bottom-5 xs:right-4 lg:bottom-5 lg:right-5 z-50">
            <Link
              href={'/contact-us'}
              className="xs:px-4 lg:px-4 xs:py-1 lg:py-1.5 bg-blue-700 rounded-[41px] shadow justify-center items-center gap-2.5 inline-flex text-center text-white text-lg font-medium"
            >
              Help ?
            </Link>
          </div>
        )}
        <Footer />
      </div>
    </Providers>
  );
}
